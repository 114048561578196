import React from 'react';
import { useModal } from 'hooks/useModal';
import { GoogleMapProps } from 'components/GoogleMap/props';

export type UseModalReturn = ReturnType<typeof useModal>;

export interface Tab {
  label: string;
  labelMobile?: string;
  value: string;
  route: string;
}

export interface DiscoverPageProps {
  filterProps?: FilterModalProps | null;
  mapProps: GoogleMapProps;
  distance: DistanceProps;
  setDistance: React.Dispatch<React.SetStateAction<DistanceProps>>;
  List: React.ReactNode;
  handleLocationRetrieval?: () => void;
  isTabInsidePage?: boolean;
  tabUpdate?: (newTabIndex: number) => void;
}

export type FilterModalPropsExtended = Pick<UseModalReturn, 'closeModal' | 'isOpen'> &
  FilterModalProps;

export interface FilterModalProps {
  children: React.ReactNode;
  title: string;
  closeButtonText: string;
  clearAll: () => void;
}

export interface DistanceDropdownProps {
  handleDistanceChange: (distance: DistanceProps) => void;
  distance: DistanceProps;
  activeTabValue: string;
}

export interface DistanceProps {
  name: string;
  id: number;
}

export enum ExploreAllMarketPlacesTypesEnum {
  Tournaments = 'tournaments',
  Lessons = 'lessons',
  Courts = 'courts',
  Groups = 'groups',
}
