import React from 'react';
import { MarkerProps } from './props';

const Marker: React.FC<MarkerProps> = ({
  lat,
  lng,
  markerId,
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      className={className}
      onClick={(e) => onClick && onClick(e, { markerId: markerId || '', lat, lng })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default Marker;
