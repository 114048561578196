import styled from 'styled-components';

// to remove blue border on focus
export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .gm-style iframe + div {
    border: none !important;
  }
`;
