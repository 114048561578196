import CloseIcon from 'svg/CloseIcon';
import EraseIcon from 'svg/Erase';
import Button, { ButtonText } from 'components/Button';
import Modal from 'components/modals/Modal';
import { FilterModalPropsExtended } from './props';

const FilterModal: React.FC<FilterModalPropsExtended> = ({
  closeModal,
  isOpen,
  children,
  title,
  clearAll,
  closeButtonText,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => closeModal()}
      classNameRounded="rounded-t-3xl sm:rounded-3xl"
      classNamePosition="relative"
      classNameMaxWidth="max-w-2xl"
    >
      <div className="relative flex h-full max-h-[90vh] flex-col overflow-hidden">
        <div>
          <div className="border-b border-color-border-input-lightmode dark:border-color-border-input-darkmode">
            <div className="flex items-center justify-between px-4 py-ds-xl md:px-ds-3xl">
              <p className="typography-product-subheading text-2xl text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                {title}
              </p>
              <ButtonText
                onClick={() => closeModal()}
                className="right-5 top-5 rounded-full p-2 font-medium transition-colors hover:bg-color-bg-lightmode-secondary dark:hover:bg-color-bg-darkmode-secondary"
              >
                <CloseIcon className="h-6 w-6" />
              </ButtonText>
            </div>
          </div>
        </div>
        {children}
        <div className="flex items-center justify-between rounded-b border-t border-solid border-color-border-input-lightmode px-4 py-6 dark:border-color-border-input-darkmode md:px-ds-3xl">
          <div className="flex items-center">
            <ButtonText
              onClick={clearAll}
              className="flex items-center rounded-full font-medium hover:bg-color-bg-lightmode-secondary hover:dark:bg-color-bg-darkmode-secondary md:px-3 md:py-2"
            >
              <EraseIcon className="mr-1 w-4" />
              Clear all
            </ButtonText>
          </div>
          <div className="md:w-1/2">
            <Button variant="primary" size="lg" onClick={() => closeModal()}>
              {closeButtonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
