import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Check from 'svg/Check';
import ChevronDown from 'svg/ChevronDown';
import classNames from 'styles/utils/classNames';
import { DistanceDropdownProps, ExploreAllMarketPlacesTypesEnum } from './props';
import { getDistanceOptions } from './utils';

const DistanceDropdown: React.FC<DistanceDropdownProps> = ({
  handleDistanceChange,
  distance,
  activeTabValue,
}) => {
  const options = getDistanceOptions(activeTabValue as ExploreAllMarketPlacesTypesEnum);

  return (
    <div className="hidden shrink-0 md:block">
      <Listbox
        value={distance.id}
        onChange={(value) => {
          handleDistanceChange(options.find(({ id }) => id === value)!);
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="hidden">Distance</Listbox.Label>
            <div className="relative z-20">
              <Listbox.Button className="typography-product-body focus-on-tab flex min-w-[7rem] items-center p-1 text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder">
                <span className="mr-ds-sm">{distance.name}</span>
                <ChevronDown className="h-4 w-4 text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder" />
              </Listbox.Button>
              <Transition
                show={open}
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="focus-on-tab absolute right-0 top-8 z-20 mt-1 w-full min-w-[10rem] overflow-auto rounded-lg border border-color-border-input-lightmode bg-color-bg-lightmode-primary shadow-popover dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary">
                  {options.map((d) => (
                    <Listbox.Option
                      key={d.id}
                      className={({ active }) =>
                        classNames(
                          'group relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-color-bg-lightmode-invert hover:text-color-text-lightmode-invert hover:dark:bg-color-bg-darkmode-invert hover:dark:text-color-text-darkmode-invert',
                          active &&
                            'bg-color-bg-lightmode-invert text-color-text-lightmode-invert dark:bg-color-bg-darkmode-invert dark:text-color-text-darkmode-invert',
                        )
                      }
                      value={d.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              'group-hover:text-color-text-lightmode-invert group-hover:dark:text-color-text-darkmode-invert',
                              active
                                ? 'bg-color-bg-lightmode-invert text-color-text-lightmode-invert dark:bg-color-bg-darkmode-invert dark:text-color-text-darkmode-invert'
                                : selected
                                ? 'text-color-text-lightmode-primary dark:text-color-text-darkmode-primary'
                                : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary',
                              'block pr-8',
                            )}
                          >
                            {d.name}
                          </span>
                          {selected ? (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <Check className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default DistanceDropdown;
