import {
  CompetitionGenderEnum,
  EventGroupFormatsEnum,
  TeamTypesEnum,
} from 'types/generated/client';

export const INITIAL_PAGE_SIZE = 25;

export const MINIMUM_COST = 0;
export const MAXIMUM_COST = 200;
export const MINIMUM_AGE = 0;
export const MAXIMUM_AGE = 99;

export function formatDateRange(startDate: Date, endDate: Date): string {
  // Formatter for the full date
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Formatter for the month and day
  const monthDayFormatter = new Intl.DateTimeFormat(undefined, {
    month: 'long',
    day: 'numeric',
  });

  // Check if start and end dates are the same day in the user's timezone
  const isSameDay = startDate.toDateString() === endDate.toDateString();

  if (isSameDay) {
    // Format as a single date
    return dateFormatter.format(startDate);
  } else {
    // Format as a date range
    return `${monthDayFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`;
  }
}

export const TOURNAMENT_DISTANCE_IMPERIAL_OPTIONS = [
  {
    name: 'Any distance',
    id: 0,
  },
  {
    name: '10 miles',
    id: 10,
  },
  {
    name: '25 miles',
    id: 25,
  },
  {
    name: '50 miles',
    id: 50,
  },
  {
    name: '100 miles',
    id: 100,
  },
  {
    name: '200 miles',
    id: 200,
  },
  {
    name: '500 miles',
    id: 500,
  },
];

export const ANY_OPTION = 'Any';

export const TEAM_TYPES = [
  {
    id: TeamTypesEnum.Doubles,
    name: 'Doubles',
  },
  {
    id: TeamTypesEnum.Singles,
    name: 'Singles',
  },
  {
    id: ANY_OPTION,
    name: 'Any',
  },
];

export const COMPETITION_FORMATS = [
  {
    id: EventGroupFormatsEnum.RoundRobin,
    name: 'Round Robin (RR)',
  },
  {
    id: EventGroupFormatsEnum.RoundRobinSingleElimination,
    name: 'RR + Single Elimination',
  },
  {
    id: EventGroupFormatsEnum.Custom,
    name: 'Other',
  },
  {
    id: ANY_OPTION,
    name: 'Any',
  },
];

export const COMPETITION_GENDER_MENU = [
  { id: CompetitionGenderEnum.Male, name: "Men's" },
  {
    id: CompetitionGenderEnum.Female,
    name: "Women's",
  },
  {
    id: CompetitionGenderEnum.Mixed,
    name: 'Mixed',
  },
];

export const TOURNAMENT_DATES = [
  {
    name: 'Next 7 Days',
    id: 7,
  },
  {
    name: 'Next 30 Days',
    id: 30,
  },
  {
    name: 'Next 90 Days',
    id: 90,
  },
  {
    name: 'Within a Year',
    id: 370,
  },
  {
    name: 'Any Date',
    id: -1,
  },
];
