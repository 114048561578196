import { useEffect, useRef, useState } from 'react';
import { Map } from 'google-maps-react-markers';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useGeoLocation } from 'hooks/useGeoLocation';
import { useInView } from 'hooks/useInView';
import FilterIcon from 'svg/FilterIcon';
import MapIcon from 'svg/Map';
import Button from 'components/Button';
import GoogleMap from 'components/GoogleMap';
import { useModal } from 'components/modals/Modal';
import classNames from 'styles/utils/classNames';
import AddressBar from './AddressBar';
import DistanceDropdown from './DistanceDropdown';
import FilterModal from './FilterModal';
import Tabs from './Tabs';
import { DiscoverPageProps, ExploreAllMarketPlacesTypesEnum } from './props';
import { tabs } from './utils';

const DiscoverPage: React.FC<DiscoverPageProps> = ({
  filterProps,
  mapProps,
  distance,
  setDistance,
  List,
  handleLocationRetrieval,
  isTabInsidePage = false,
  tabUpdate,
}) => {
  const mapRef = useRef<Map>();
  const { closeModal, openModal, isOpen: isModalOpen } = useModal();
  const discoverContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOpenMap, setIsOpenMap] = useState<boolean>(false);
  const { position, getEstimatedLocation, requestUserLocation, hasLocationPermission } =
    useGeoLocation();

  const router = useRouter();

  const isDiscoverSectionInView = useInView(discoverContainerRef);

  const getInitialTabIndex = (): number => {
    const currentPath = router.pathname.split('/').pop();
    const tabIndex = tabs.findIndex((tab) => tab.value === currentPath);

    return tabIndex !== -1 ? tabIndex : 0;
  };

  const [activeTabIndex, setActiveTabIndex] = useState<number>(getInitialTabIndex());

  const handleTabChange = (newTabIndex: number) => {
    setActiveTabIndex(newTabIndex);
    if (tabUpdate && isTabInsidePage) tabUpdate(newTabIndex);
  };

  const handleGoogleApiLoaded = (map: Map) => {
    mapRef.current = map;
  };

  const handleToggleMap = () => {
    setIsOpenMap((curr) => !curr);
  };

  useEffect(() => {
    if (discoverContainerRef.current && isOpenMap)
      discoverContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
  }, [isOpenMap]);

  useEffect(() => {
    const getLocation = async () => {
      try {
        await hasLocationPermission();
        await requestUserLocation();
      } catch (error) {
        await getEstimatedLocation();
      } finally {
        handleLocationRetrieval?.();
      }
    };

    if (!position) {
      getLocation();
    } else {
      handleLocationRetrieval?.();
    }
  }, [position]);

  useEffect(() => {
    if (mapProps) {
      mapRef.current?.setCenter({
        lat: mapProps.defaultCenter?.lat,
        lng: mapProps.defaultCenter?.lng,
      });
    }
  }, [mapProps?.defaultCenter?.lat, mapProps?.defaultCenter?.lng, mapRef]);

  return (
    <>
      <div
        ref={discoverContainerRef}
        className={classNames(
          'relative grid w-full grid-cols-[minmax(0,1fr)_45%] grid-rows-[auto_1fr]',
          isOpenMap
            ? "[grid-template-areas:'header_map''list_map'] max-lg:h-[calc(100vh-theme(height.topnav))] max-lg:[grid-template-areas:'header_header''map_map']"
            : "h-full max-lg:[grid-template-areas:'header_header''list_list'] lg:min-h-[calc(100vh-theme(height.topnav))] lg:[grid-template-areas:'header_map''list_map']",
        )}
      >
        <div
          className={classNames(
            'sticky top-topnav z-10 flex flex-col gap-4 bg-color-bg-lightmode-primary p-4 dark:bg-color-bg-darkmode-primary',
            '[grid-area:header]',
          )}
        >
          <div className="flex items-center gap-6">
            <AddressBar />
            <DistanceDropdown
              distance={distance}
              handleDistanceChange={(distance) => setDistance(distance)}
              activeTabValue={tabs[activeTabIndex].value as ExploreAllMarketPlacesTypesEnum}
            />
          </div>

          <div className="flex items-center justify-between gap-2">
            <div className="w-full min-w-0 max-w-[40.645rem]">
              <Tabs
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                onTabChange={handleTabChange}
                isTabInsidePage={isTabInsidePage}
              />
            </div>
            {filterProps && (
              <Button
                onClick={() => openModal()}
                variant="inverted"
                className="max-w-32 shrink max-sm:hidden"
                size="md"
                iconLeft={
                  <FilterIcon className="h-4 w-4 text-color-bg-lightmode-invert dark:text-color-bg-darkmode-invert" />
                }
              >
                Filters
              </Button>
            )}
          </div>
        </div>

        {/* List section */}
        <div
          className={classNames(
            'flex min-w-0 flex-col overflow-auto',
            '[grid-area:list]',
            isOpenMap ? 'hidden lg:flex' : 'flex',
          )}
        >
          {List}
        </div>

        {/* Map section */}
        <div
          className={classNames(
            'sticky [grid-area:map]',
            isOpenMap
              ? 'block lg:top-[theme(height.topnav)] lg:h-[calc(100vh-theme(height.topnav))]'
              : 'max-lg:hidden lg:top-[theme(height.topnav)] lg:h-[calc(100vh-theme(height.topnav))]',
          )}
        >
          <GoogleMap
            {...mapProps}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              disableDefaultUI: true,
              clickableIcons: false,
            }}
            onGoogleApiLoaded={handleGoogleApiLoaded}
          />
        </div>
      </div>

      {/* Filter Modal */}
      {filterProps && (
        <FilterModal
          title={filterProps.title}
          isOpen={isModalOpen}
          closeModal={closeModal}
          clearAll={filterProps.clearAll}
          closeButtonText={filterProps.closeButtonText}
        >
          {filterProps.children}
        </FilterModal>
      )}

      {/* Map toggle Button */}
      <div
        className={classNames(
          'fixed bottom-8 right-1/2 z-10  flex  h-11 translate-x-1/2 transform gap-6 rounded-full bg-color-bg-darkmode-primary px-6 text-sm text-color-text-lightmode-invert max-md:bottom-[7rem] lg:hidden',
          !isDiscoverSectionInView && 'hidden',
        )}
      >
        <div className="flex cursor-pointer items-center justify-center" onClick={handleToggleMap}>
          <MapIcon className="h-5 w-5 text-color-text-lightmode-invert dark:text-color-text-darkmode-invert" />
          <p className="ml-2 select-none whitespace-nowrap">
            {isOpenMap ? 'Hide map' : 'Show map'}
          </p>
        </div>

        {filterProps && (
          <>
            <div className="hidden h-full w-[1px] bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary max-sm:block" />

            <div
              className="hidden cursor-pointer items-center justify-center max-sm:flex"
              onClick={() => openModal()}
            >
              <p className="mr-2 select-none whitespace-nowrap">Filters</p>
              <FilterIcon className="h-4 w-4 text-color-text-lightmode-invert dark:text-color-text-darkmode-invert" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DiscoverPage;
