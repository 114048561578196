import {
  COACH_MARKETPLACE_PAGE,
  COURT_FINDER_PAGE,
  GROUPS_MARKETPLACE_PAGE,
  TOURNAMENTS_PAGE,
} from 'constants/pages';
import { VENUE_DISTANCE_IMPERIAL_OPTIONS } from 'components/Explore/Groups/utils';
import { TOURNAMENT_DISTANCE_IMPERIAL_OPTIONS } from 'components/Explore/Tournaments/utils';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ExploreAllMarketPlacesTypesEnum } from './props';

export const CardLoadingSkeleton = () => {
  return (
    <div className="w-full flex-shrink-0 overflow-hidden rounded-lg lg:max-w-[26rem]">
      <LoadingSkeleton height="11.25rem" />
      <div className="mt-2.5">
        <LoadingSkeleton count={3} />
      </div>
    </div>
  );
};

export const loadingSkeletonGrid = [
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
  CardLoadingSkeleton,
];

export const getDistanceOptions = (tab: ExploreAllMarketPlacesTypesEnum) => {
  switch (tab) {
    case ExploreAllMarketPlacesTypesEnum.Tournaments:
      return TOURNAMENT_DISTANCE_IMPERIAL_OPTIONS;
    case ExploreAllMarketPlacesTypesEnum.Courts:
      return VENUE_DISTANCE_IMPERIAL_OPTIONS;
    case ExploreAllMarketPlacesTypesEnum.Lessons:
      return VENUE_DISTANCE_IMPERIAL_OPTIONS;
    case ExploreAllMarketPlacesTypesEnum.Groups:
      return VENUE_DISTANCE_IMPERIAL_OPTIONS;

    default:
      return VENUE_DISTANCE_IMPERIAL_OPTIONS;
  }
};

export const tabs = [
  {
    label: 'Courts',
    value: ExploreAllMarketPlacesTypesEnum.Courts,
    route: COURT_FINDER_PAGE,
  },
  {
    label: 'Lessons',
    value: ExploreAllMarketPlacesTypesEnum.Lessons,
    route: COACH_MARKETPLACE_PAGE,
  },
  {
    label: 'Tournaments',
    labelMobile: 'Tourney',
    value: ExploreAllMarketPlacesTypesEnum.Tournaments,
    route: TOURNAMENTS_PAGE,
  },
  {
    label: 'Groups',
    value: ExploreAllMarketPlacesTypesEnum.Groups,
    route: GROUPS_MARKETPLACE_PAGE,
  },
];
