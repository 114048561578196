import React from 'react';
import Button from 'components/Button';
import Link from 'components/Link';
import classNames from 'styles/utils/classNames';
import { Tab } from './props';

interface TabsProps {
  tabs: Tab[];
  activeTabIndex: number;
  onTabChange: (value: number) => void;
  isTabInsidePage?: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTabIndex = 0,
  onTabChange,
  isTabInsidePage = true,
}) => {
  return (
    <div className="relative flex flex-col">
      <div className="relative flex h-10 justify-center rounded-full bg-color-bg-lightmode-secondary p-1 dark:bg-color-bg-darkmode-secondary">
        {/* Add transition-transform duration-300 for animation */}
        <div
          className={classNames(
            'absolute bottom-1 left-0 right-0 top-1 rounded-3xl bg-color-bg-lightmode-brand  dark:bg-color-bg-darkmode-brand',
          )}
          style={{
            transform: `translateX(calc(${activeTabIndex * 100}%  + ${
              activeTabIndex === 0 ? '4px' : activeTabIndex === tabs.length - 1 ? '-4px' : '0px'
            } ))`,
            maxWidth: `calc(100% / ${tabs.length})`,
          }}
        ></div>
        {tabs.map((tab, tabIndex) =>
          isTabInsidePage ? (
            <button
              key={tab.value}
              onClick={() => onTabChange(tabIndex)}
              className={`transition-color relative flex h-8 w-full flex-1 shrink items-center justify-center overflow-hidden text-ellipsis rounded-3xl px-6 duration-300 ${
                activeTabIndex === tabIndex
                  ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
                  : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
              }`}
            >
              <span className="hidden md:inline">{tab.label}</span>
              <span className="md:hidden">{tab.labelMobile || tab.label}</span>
            </button>
          ) : (
            <Link
              key={tab.value}
              onClick={() => onTabChange(tabIndex)}
              href={tab.route}
              className={`transition-color relative flex h-8 w-full flex-1 shrink items-center justify-center overflow-hidden text-ellipsis rounded-3xl px-6 duration-300 ${
                activeTabIndex === tabIndex
                  ? 'text-color-text-lightmode-invert dark:text-color-text-darkmode-invert'
                  : 'text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary'
              }`}
            >
              <span className="hidden md:inline">{tab.label}</span>
              <span className="md:hidden">{tab.labelMobile || tab.label}</span>
            </Link>
          ),
        )}
      </div>
    </div>
  );
};

export default Tabs;
