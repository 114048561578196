const NoDataPlaceholder: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="mx-4 mb-ds-xl mt-ds-xl grid h-60  place-items-center rounded-xl bg-color-bg-lightmode-secondary px-4 text-center dark:bg-color-bg-darkmode-secondary">
      <span className="typography-product-text-card text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
        {text}
      </span>
    </div>
  );
};

export default NoDataPlaceholder;
