import { RefObject, useEffect, useState } from 'react';

interface InViewOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export function useInView<T extends HTMLElement>(
  ref: RefObject<T>,
  inViewOptions: InViewOptions = {},
): boolean {
  const [inView, setInView] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setInView(entry.isIntersecting);
        });
      },
      {
        root: inViewOptions.root || null,
        rootMargin: inViewOptions.rootMargin || '0px',
        threshold: inViewOptions.threshold || 0,
      },
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref, inViewOptions]);

  return inView;
}
