import React from 'react';
import GoogleMapReactMarker, { Map } from 'google-maps-react-markers';
import Marker from './Marker';
import { GoogleMapProps } from './props';
import { MapContainer } from './styles';

const GoogleMap: React.FC<GoogleMapProps> = ({
  defaultCenter,
  zoom = 12,
  markers = [],
  mapId,
  options = {},
  onChange,
  onGoogleApiLoaded,
  isExactCenter = false,
  centerIcon,
}) => {
  const handleGoogleApiLoaded = ({ map, maps }: { map: Map; maps: any }) => {
    onGoogleApiLoaded && onGoogleApiLoaded(map, maps);
  };

  return (
    <MapContainer>
      <GoogleMapReactMarker
        apiKey={process.env.GOOGLE_MAPS_API_KEY as string}
        defaultCenter={defaultCenter}
        defaultZoom={zoom}
        options={{ gestureHandling: 'greedy', ...options, mapId }}
        onChange={onChange}
        onGoogleApiLoaded={handleGoogleApiLoaded}
      >
        {markers.map((marker, index) => (
          <Marker key={index} {...marker} />
        ))}
        {isExactCenter && centerIcon && (
          <Marker lat={defaultCenter.lat} lng={defaultCenter.lng}>
            {centerIcon}
          </Marker>
        )}
      </GoogleMapReactMarker>
    </MapContainer>
  );
};

export default GoogleMap;
