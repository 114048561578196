import { useGeoLocation } from 'hooks/useGeoLocation';
import Location from 'svg/Location';
import SearchIcon from 'svg/SearchIcon';
import Button from 'components/Button';
import AddressSearch from 'components/forms/AddressSearch';

const AddressBar: React.FC = () => {
  const {
    addressString,
    suggestedAddresses,
    activeAutocompleteAddress,
    handleSubmitAutocomplete,
    handleAutcompleteSuggestions,
  } = useGeoLocation();

  const handleSubmit = async (address?: google.maps.places.AutocompletePrediction | null) => {
    await handleSubmitAutocomplete(address);
  };

  return (
    <div className="flex w-full grow select-none items-center rounded-full bg-color-bg-input-lightmode-primary py-ds-xs pl-ds-md pr-ds-xs dark:bg-color-bg-input-darkmode-primary">
      <Location className="h-5 w-5 text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder" />
      <AddressSearch
        handleSubmit={handleSubmit}
        handleAutcompleteSuggestions={handleAutcompleteSuggestions}
        addressString={addressString}
        suggestedAddresses={suggestedAddresses}
        activeAutocompleteAddress={activeAutocompleteAddress}
        className="input-unstyled typography-product-body w-full border-none bg-transparent py-0 outline-none outline-0 outline-transparent ring-transparent focus:outline-none focus:outline-0 active:outline-0"
        placeholder="Search by location"
      />
      <Button type="submit" variant="brand" size="icon" isInline className="p-2">
        <SearchIcon className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default AddressBar;
